<template>
  <v-footer :color="'transparent'">
    <v-card-text
      :class="`text-center caption ${
        isMobile
          ? 'login-footer-mobile white--text'
          : 'secondary--text text--lighten-2'
      }`"
    >
      {{ $t("login.createAccountTerms") }}
      <a
        :class="`primary--text font-weight-bold px-1 py-0 ${
          isMobile ? 'accent--text' : 'primary--text'
        } text-wrap break-word`"
        href="https://conectasuite.com/termos-de-uso"
        target="_blank"
      >
        {{ $t("login.termsOfUse") }}
      </a>
      {{ $t("common.and") }}
      <a
        :class="`primary--text font-weight-bold px-1 py-0 ${
          isMobile ? 'accent--text' : 'primary--text'
        } text-wrap break-word`"
        href="https://conectasuite.com/politica-de-privacidade"
        target="_blank"
      >
        {{ $t("login.privacyPolicy") }}
      </a>
      <SelectLanguage />
    </v-card-text>
  </v-footer>
</template>
<script>
import SelectLanguage from "@/components/base/SelectLanguage";
export default {
  name: "LoginFooter",
  components: {
    SelectLanguage,
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>
<style>
.login-footer-mobile {
  background-color: rgba(78, 58, 100, 0.6);
}
</style>
